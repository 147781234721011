import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Route,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { AuthGuardUsersService } from './auth-guard-userssers.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardUsersGuard implements CanActivate {
    constructor(
        private userAuth: AuthGuardUsersService,
        private router:Router,
        private snackBar: MatSnackBar
    ) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.userAuth.checkUserIsLogged() ) {
            
        //   this.snackBar.open('You Don\'t Have Permission', 'Dismiss', {
        //     duration: 5000,
        // });
            return true;
        } else {
            this.snackBar.open("Not Authorized to access this resource ", 'Dismiss', {
                duration: 10000,
            });
              sessionStorage.clear();
              this.router.navigate(['/'])
            // this.router.navigate(["/sign-out"]);
            // // this.router.navigate(["/sign-in"]);
            return false;
        }
    }
}
