import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { QuestionBankListResolver } from './modules/questionbank/list/common/questionbank.resolvers';
import { AuthGuardUsersGuard } from './modules/shared/Auth_guard_users/auth-guard-users.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'dashboard',  }, 

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard'  },

    // Auth routes for guests
    {
        path: '',
        canMatch: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.module'
                    ).then((m) => m.AuthConfirmationRequiredModule),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.module'
                    ).then((m) => m.AuthForgotPasswordModule),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.module'
                    ).then((m) => m.AuthResetPasswordModule),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.module').then(
                        (m) => m.AuthSignInModule
                    ),
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.module').then(
                        (m) => m.AuthSignUpModule
                    ),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then(
                        (m) => m.AuthSignOutModule
                    ),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.module'
                    ).then((m) => m.AuthUnlockSessionModule),
            },
        ],
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/landing/home/home.module').then(
                        (m) => m.LandingHomeModule
                    ),
            },
        ],
    },

    // Admin routes
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'dashboard',
                loadChildren: () =>
                    import('app/modules/admin/example/example.module').then(
                        (m) => m.ExampleModule
                    ),
                    canActivate: [AuthGuardUsersGuard],
            },
            {
                path: 'questionBank',
                loadChildren: () =>
                    import('app/modules/questionbank/questionbank.module').then(
                        (m) => m.QuestionbankModule
                    ),
                    canActivate: [AuthGuardUsersGuard],
            },
            {
                path: 'masterData',
                loadChildren: () =>
                    import('app/modules/masterdata/master-data.module').then(
                        (m) => m.MasterDataModule
                    ),
                canActivate: [AuthGuardUsersGuard],
            },
            {
                path: 'user',
                loadChildren: () =>
                    import('app/modules/user/user.module').then(
                        (m) => m.UserModule
                    ),
                canActivate: [AuthGuardUsersGuard],
            },
            {
                path: 'events',
                children: [
                    {
                        path: 'eventsMaster',
                        loadChildren: () =>
                            import(
                                'app/modules/events/events.module'
                            ).then((m) => m.EventsModule),
                    },
                    {
                        path: 'eventReg',
                        loadChildren: () =>
                            import(
                                'app/modules/eventsReg/eventReg.module'
                            ).then((m) => m.EventRegModule),
                    },
                    {
                        path: 'winners',
                        loadChildren: () =>
                            import(
                                'app/modules/events/winners/winners.module'
                            ).then((m) => m.WinnersModule),
                    },
                    {
                        path: 'event-mock',
                        loadChildren: () =>
                            import(
                                'app/modules/events/event-mock/event-mock.module'
                            ).then((m) => m.EventMockModule),
                    },
                    {
                        path: 'fcm',
                        loadChildren: () =>
                            import(
                                'app/modules/events/fcm/fcm.module'
                            ).then((m) => m.FcmModule),
                    },
                ],
                canActivate: [AuthGuardUsersGuard],
            },
            {
                path: 'practice',
                loadChildren: () =>
                    import('app/modules/practice/practice.module').then(
                        (m) => m.PracticeModule
                    ),
                canActivate: [AuthGuardUsersGuard],
            },
            {
                path: 'topicMaster',
                loadChildren: () =>
                    import('app/modules/topic-master/topic-master.module').then(
                        (m) => m.TopicMasterModule
                    ),
                canActivate: [AuthGuardUsersGuard],
            },
            {
                path: 'rewardsMaster',
                loadChildren: () =>
                    import(
                        'app/modules/rewards-master/rewards-master.module'
                    ).then((m) => m.RewardsMasterModule),
                canActivate: [AuthGuardUsersGuard],
            },
            {
                path: 'progress',
                loadChildren: () =>
                    import('app/modules/progress/progress.module').then(
                        (m) => m.ProgressModule
                    ),
                canActivate: [AuthGuardUsersGuard],
            },
            {
                path: 'customer',
                loadChildren: () =>
                    import('app/modules/customer/customer.module').then(
                        (m) => m.CustomerModule
                    ),
                canActivate: [AuthGuardUsersGuard],
            },
            {
                path: 'license',
                loadChildren: () =>
                    import('app/modules/license/license.module').then(
                        (m) => m.LicenseModule
                    ),
                canActivate: [AuthGuardUsersGuard],
            },
            {
                path: 'analytics',
                loadChildren: () =>
                    import('app/modules/license/license.module').then(
                        (m) => m.LicenseModule
                    ),
                canActivate: [AuthGuardUsersGuard],
            },
            {
                path: 'analytics',
                children: [
                    {
                        path: 'leaderboard',
                        loadChildren: () =>
                            import(
                                'app/modules/analytics/leaderboard/leaderboard.module'
                            ).then((m) => m.LeaderboardModule),
                    },
                    {
                        path: 'insights',
                        loadChildren: () =>
                            import(
                                'app/modules/analytics/insights/insights.module'
                            ).then((m) => m.InsightsModule),
                    },
                    {
                        path: 'onboardingreport',
                        loadChildren: () =>
                            import(
                                'app/modules/analytics/onboarding-report/onboarding-report.module'
                            ).then((m) => m.OnboardingReportModule),
                    },
                    {
                        path: 'progressreport',
                        loadChildren: () =>
                            import(
                                'app/modules/analytics/progress-report/progress-report.module'
                            ).then((m) => m.ProgressReportModule),
                    },
                    {
                        path: 'rewardsreport',
                        loadChildren: () =>
                            import(
                                'app/modules/analytics//rewards-report/rewards-report.module'
                            ).then((m) => m.RewardsReportModule),
                    },
                ],
                canActivate: [AuthGuardUsersGuard],
            },
            {
                path: 'store',
                children: [
                    {
                        path: 'storeitems',
                        loadChildren: () =>
                            import(
                                'app/modules/store/store-items/store-items.module'
                            ).then((m) => m.StoreItemsModule),
                    },
                    {
                        path: 'ledger',
                        loadChildren: () =>
                            import(
                                'app/modules/store/ledger/ledger.module'
                            ).then((m) => m.LedgerModule),
                    },
                    {
                        path: 'claims',
                        loadChildren: () =>
                            import(
                                'app/modules/store/claims/claims.module'
                            ).then((m) => m.ClaimsModule),
                    },
                ],
                canActivate: [AuthGuardUsersGuard],
            },
        ],
    },
];
