import { Injectable } from '@angular/core';

export enum Environment {
    Prod = 'prod',
    Dev = 'dev',
    Local = 'local',
}

@Injectable({
    providedIn: 'root',
})
export class EnvService {
    private _env: Environment;
    private _apiUrl: string;
    private _googleCaptchaSecretKey: string;
    licenseKey: string;
    get env(): Environment {
        return this._env;
    }
    get apiUrl(): string {
        return this._apiUrl;
    }
    get googleCapchaSecretKey(): string {
        return this._googleCaptchaSecretKey;
    }

    init(): Promise<void> {
        return new Promise((resolve) => {
            this.setEnvVariables();
            resolve();
        });
    }
    // http://localhost:3000
    private setEnvVariables(): void {
        const hostname = window && window.location && window.location.hostname;
        if (/localhost/.test(hostname)) {
            this._env = Environment.Local;
            // this._apiUrl = 'https://fun2do.appmocx.com';
            // this._apiUrl = 'https://f2d.fun2do.co';
            this._apiUrl = 'http://localhost:3000';
            this.licenseKey = '';
            this._googleCaptchaSecretKey = '';
        } else if (/^fun2do.appmocx.com/.test(hostname)) {
            this._env = Environment.Dev;
            this.licenseKey = '';
            this._apiUrl = 'https://fun2do.appmocx.com';
            this._googleCaptchaSecretKey =
                '6LfOl50fAAAAAP278NEhytmVtzEO1Hd7WSykWmKH';
        } else if (/^f2d.fun2do.co/.test(hostname)) {
            this._env = Environment.Prod;
            this.licenseKey = '';
            this._apiUrl = 'https://f2d.fun2do.co';
            this._googleCaptchaSecretKey = '';
        } else {
            console.warn(`Cannot find environment for host name ${hostname}`);
        }
    }
}
