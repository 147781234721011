/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    },
    {
        id   : 'events',
        title: 'Events',
        type : 'basic',
        icon : 'mat_outline:event',
        link : '/events'
    },
    {
        id   : 'event reg',
        title: 'Event Register',
        type : 'basic',
        icon : 'mat_outline:emoji_events',
        link : '/eventReg'
    },
    {
        id   : 'questionBank',
        title: 'Question Bank',
        type : 'basic',
        icon : 'mat_outline:question_answer',
        link : '/questionBank'
    },
    {
        id   : 'masterData',
        title: 'Master Data',
        type : 'basic',
        icon : 'mat_outline:insert_drive_file',
        link : '/masterData'
    },
    {
        id   : 'masterData',
        title: 'User',
        type : 'basic',
        icon : 'feather:user',
        link : '/user'
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'mat_outline:event',
        link : '/dashboard'
    },
    ,
    {
        id   : 'events',
        title: 'Events',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/events'
    },
    {
        id   : 'questionBank',
        title: 'Question Bank',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/questionBank'
    },
    {
        id   : 'masterData',
        title: 'Master Data',
        type : 'basic',
        icon : 'mat_outline:insert_drive_file',
        link : '/masterData'
    },
    {
        id   : 'masterData',
        title: 'User',
        type : 'basic',
        icon : 'feather:user',
        link : '/user'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    },
    ,
    {
        id   : 'events',
        title: 'Events',
        type : 'basic',
        icon : 'mat_outline:event',
        link : '/events'
    },
    {
        id   : 'questionBank',
        title: 'Question Bank',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/questionBank'
    },
    {
        id   : 'masterData',
        title: 'Master Data',
        type : 'basic',
        icon : 'mat_outline:insert_drive_file',
        link : '/masterData'
    },
    {
        id   : 'masterData',
        title: 'User',
        type : 'basic',
        icon : 'feather:user',
        link : '/user'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/dashboard'
    },
    ,
    {
        id   : 'events',
        title: 'Events',
        type : 'basic',
        icon : 'mat_outline:event',
        link : '/events'
    },
    {
        id   : 'questionBank',
        title: 'Question Bank',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/questionBank'
    },
    {
        id   : 'masterData',
        title: 'Master Data',
        type : 'basic',
        icon : 'mat_outline:insert_drive_file',
        link : '/masterData'
    },
    {
        id   : 'masterData',
        title: 'User',
        type : 'basic',
        icon : 'feather:user',
        link : '/user'
    }
];
