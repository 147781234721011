import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable,  throwError } from 'rxjs';

import { catchError } from "rxjs/operators";
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor( private router: Router, private snackBar: MatSnackBar) {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // let token =localStorage.getItem('accessToken');
    let token = sessionStorage.getItem('accessToken');
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        // 'x-tenant-id': environment.app_id // Sending the tenant id on every request. 
      }
    });
    return next.handle(request).pipe(
  
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status == 401) {
            this.clearCookie()
          }
        }
        return throwError(err);
      }));
  }
  clearCookie() {
    // localStorage.clear();
    // location.reload();
    this.snackBar.open("Not Authorized to access this resource ", 'Dismiss', {
      duration: 10000,
  });
    sessionStorage.clear();
    this.router.navigate(['/'])
  }
}