import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, of, Subject, switchMap, throwError, ReplaySubject, tap } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { EnvService } from 'environments/env.service';
import jwt_decode from "jwt-decode";
import { MenuMaster } from '../user/menuItems.types';

@Injectable()
export class AuthService {
    private _authenticated: boolean = false;
    apiUrl: string;
    tokendecrypt: any;
    private $menuMaster: ReplaySubject<MenuMaster> = new ReplaySubject<MenuMaster>();
    usersMenuList: MenuMaster;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private _envService:EnvService
    ) {
        this.apiUrl=this._envService.apiUrl;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        // localStorage.setItem('accessToken', token);
        sessionStorage.setItem("accessToken", token);
    }

    get accessToken(): string {
        // return localStorage.getItem('accessToken') ?? '';
        return sessionStorage.getItem("accessToken") ?? '';
    }

    ////// Setter & Getter for Menus: To store menu items in localstorate
    // private set usersMenuList(menuMaster: MenuMaster) {
    //     if (menuMaster != null) {
    //         // localStorage.setItem('userMenuList', JSON.stringify(menuMaster));
    //         sessionStorage.setItem("userMenuList", JSON.stringify(menuMaster));
    //     }
    //     else {
    //         sessionStorage.removeItem('userMenuList');
    //         // localStorage.removeItem('userMenuList');
    //     }
    // }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { username: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            // localStorage.removeItem('accessToken');
            sessionStorage.removeItem('accessToken');
            return throwError('User is already logged in.');
        }

        return (
            this._httpClient
                .post(`${this.apiUrl}/api/v1/user/login`, credentials)
                // .post('api/auth/sign-in', credentials)
                .pipe(
                    switchMap((response: any) => {
                        // console.log(response);
                        // console.log(response.accessToken);
                        // Store the access token in the local storage
                        this.accessToken = response.data[0].accessToken;
                        // this.accessToken = response.accessToken;

                        // Set the authenticated flag to true
                        this._authenticated = true;

                        // Store the user on the user service
                        this._userService.user =
                        {
                            avatar: 'assets/images/avatars/brian-hughes.jpg',
                            email: 'hughes.brian@company.com',//not need only
                            id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
                            name: 'Brian Hughes',
                            status: 'online',//not need
                        };
                        // Return a new observable with the response
                        return of(this.accessToken);
                    })
                )
        );
    }

    getUser(){
        // let token = localStorage.getItem('accessToken')
        let token = sessionStorage.getItem('accessToken')
        this.tokendecrypt = jwt_decode(token);
        return  this.tokendecrypt;
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        // return of(false);
        return this._httpClient
            .post('api/auth/sign-in-with-token', {
                accessToken: this.accessToken,
            })
            .pipe(
                catchError(() =>
                    // Return false
                    of(false)
                ),
                switchMap((response: any) => {
                    // Replace the access token with the new one if it's available on
                    // the response object.
                    //
                    // This is an added optional step for better security. Once you sign
                    // in using the token, you should generate a new one on the server
                    // side and attach it to the response object. Then the following
                    // piece of code can replace the token with the refreshed one.
                    if (response.accessToken) {
                        this.accessToken = response.accessToken;
                    }

                    // Set the authenticated flag to true
                    this._authenticated = true;

                    // Store the user on the user service
                    this._userService.user = response.user;

                    // Return true
                    return of(true);
                })
            );
    }

    //To get authenticated user's access menu
    get menuMasterList$(): Observable<MenuMaster>
    {
        return this.$menuMaster.asObservable();
    }

    public getMenuListForUser(): Observable<MenuMaster> {
        let activeUser = this.getUser();
        let userRole =  activeUser.data['role'];

        return this._httpClient.get<MenuMaster>(this._envService.apiUrl + `/api/v1/user/menus/${userRole}`).pipe(
            tap((menu) => {
                this.$menuMaster.next(menu);
                this.usersMenuList = menu;
                
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        // localStorage.removeItem('accessToken');
        sessionStorage.clear();
        // this.usersMenuList = null;

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: {
        name: string;
        email: string;
        password: string;
        company: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: {
        email: string;
        password: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
