import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuardUsersService } from './auth-guard-userssers.service';
import { AuthGuardUsersGuard } from './auth-guard-users.guard';
import { MatSnackBarModule } from '@angular/material/snack-bar';


@NgModule({
    declarations: [],
    imports: [CommonModule,MatSnackBarModule],
    exports: [],
    providers:[AuthGuardUsersService]
})
export class AuthGuardModule {}