import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardUsersService {
    constructor(private auth: AuthService) {
      
    }

    checkUserIsLogged() {
        const rolename = this.auth.getUser().data.accessFor;

        // CONTRIBUTOR
        if (rolename == 'PUBLISHER' || rolename == "SUPER_ADMIN") {
            return true;
        } else {
            return false;
        }
    }

    // checkUserRole() {
    //     const rolename = this.auth.getUser().data.accessFor;
    //     if (rolename == 'PUBLISHER') {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }
}
